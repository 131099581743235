<template>
  <app-list-view
    server-side
    app="audit"
    model="task"
    api-url="/task/task/"
    :title="$t('menu.preAuditTask')"
    :createTo="{ name: 'taskCreate' }"
    :editTo="{ name: 'taskEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'taskList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.name'), value: 'name' },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  }
}
</script>
